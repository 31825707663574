import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader } from "mdbreact";
import RecentNews from "./RecentNews";
import HomePageNews from "./News/HomePageNews";
import HomePageEvents from "./News/HomePageEvents";
import { Grid, Typography } from "@material-ui/core";
import { LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import HomePageFeatured from "./Essentials/HomePageFeatured";
import TakeATour from "./Essentials/TakeATour";
import Hightlights from "./Hightlights";
import GraphContainer from "./Essestials/GraphContainer";
import VideoPlayer from "./Essentials/VideoPlayer";
import Testimonials from "./Essentials/Testimonials";
import Placements from "./Essentials/Placements";
import CollegeAnthem from "./Essentials/CollegeAnthem";
import VideoComponent from "./Video/VideoComponent";

export default class HomePagePandu extends Component {
  render() {
    return (
      <div className="fontFix">
        {/* <div
          style={{
            width: "100%",
            height: "70vh",
            background:
              "url('/images/bg2.jpg')",
            backgroundRepeat: "no repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              background: "linear-gradient(180deg,#000A,#0005,#000A)",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "2em 0",
              alignItems: 'center',
            }}
          >
            
          </div>
        </div> */}

        <div >
          <RecentNews />
        </div>

        <MDBContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div style={{ padding: 10, backgroundColor: "#14405C" }}>
                    <img
                      src={"/images/dc-principal-new.jpeg"}
                      alt="dc-principal"
                      style={{ width: "100%", marginBottom: 5 }}
                    />
                    <Typography
                      style={{
                        marginTop: 10,
                        fontWeight: 700,
                        color: "white",
                        fontSize: 14,
                      }}
                    >
                      <i>
                        Dear Students,
                        <br />I would like to take this opportunity to heartily
                        invite each one of you, who desires to gain knowledge
                        and be an asset to the society and the nation to the
                        premises of Dispur College. The College named under the
                        capital of Assam has produced many eminent figures and
                        we are still in the journey to continue producing many
                        more eminent personalities who can make the state and
                        the nation proud. .......
                        <Link
                          to="/administrations/principal"
                          style={{ color: "white" }}
                        >
                          See More
                        </Link>
                      </i>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <div className="text-justify" style={{}}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      About Dispur College
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "16px" }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>Dispur College</strong> was established in the
                      periphery of beautiful Narakasur Hills way back in 1978 by
                      some visionaries of the locality primarily to cater the
                      needs of children of the working parents in the Capital
                      secretariat of Assam. The College has already completed
                      44th years of its glorious existence having 12 academic
                      departments both in Arts and Commerce Stream along with
                      highly efficient vocational departments in BBA & BCA. The
                      College has always been looking forward in creating a
                      strong bond between students and teachers for better
                      performance in academic and other related activities. The
                      institution has a primary objective of facilitation of
                      higher education to the socio economic backward section of
                      the society. With this motto, the College has been able to
                      become a destination for large number of students across
                      various communities and places of entire Northeast India.
                      Among all social, cultural and other activities the
                      students execute work unitedly for realization of basic
                      objectives of education. In spite of some physical
                      limitations, presently the college has an enrollment of
                      2000 students in different faculties pursuing their goals
                      of life. A continuous effort is being made to transform
                      the college into an ideal model institution not only for
                      the state of Assam but also for the whole of North East
                      India....
                      <a href="/aboutus/about">Read More</a>
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <br />
              <Grid
                container
                spacing={4}
                style={{ backgroundColor: "#f9f9f9", padding: 10 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="text-justify" style={{}}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      Vision
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "17px" }}>
                      Dispur College aspires to be a nationally acclaimed Higher
                      Educational Institution. It is our persistent endeavour to
                      remain resolute and unwavering in the pursuit of knowledge
                      aligned with the motto of the institution “Tamso Ma
                      Jyotirgamaya(तमसोमाज्योतिर्गमय)” and positively contribute
                      towards holistic education which focus on nurturing
                      student’s talent and personal development; promoting
                      intellectual growth; developing professional skills;
                      fostering gender equity and stimulating employability and
                      to prepare them to work for holistic development of the
                      society and the nation.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="text-justify" style={{}}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      Mission
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "17px" }}>
                      Pledged to achieve our Vision through our Core Values and
                      to educate the students to the highest levels of academic
                      accomplishment, to empower them to reach and expand their
                      prospective and to prepare them to become ethical,
                      accountable, compassionate, productive, inventive members
                      of the society as well as worthy citizens of the state.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700 }}
                    gutterBottom
                  >
                    Core Values
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "17px" }}>
                    <ol>
                      <li>
                        Integrity: Acting in accordance with the highest
                        academic, professional and ethical standards is a
                        priority for one and all representing the educational
                        institution.
                      </li>
                      <li>
                        Responsibility: We are aware that our moves influence
                        the world around us and based on that apprehension, we
                        make the conscious choice to take actions to improve our
                        community and the lives of each and everyone around us.
                      </li>
                      <li>
                        Diversity and Inclusion: The institution respects and
                        appreciates differences in socio-economic background,
                        education, ethnicity, colour, gender, age, caste, race,
                        religion, physical abilities etc. among individuals. We
                        share an environment where everyone has equal
                        opportunity to contribute ideas.
                      </li>
                      <li>
                        Excellence: We strive for excellence in all our
                        endeavors as individuals, as an institution, and as a
                        leader in higher education.
                      </li>
                      <li>
                        Teamwork: We understand that team values allow us to
                        perform our roles effectively and contribute to
                        Institution's success.
                      </li>
                      <li>
                        Transparency: We believe in accessibility, compliance,
                        communication and accountability.
                      </li>
                      <li>
                        Environmental Sustainability: We value adopting
                        practices for minimising our impact on the environment
                        to the benefit of a more sustainable future for
                        posterity.
                      </li>
                    </ol>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <h2 className="subhead">Quick Links</h2>
              <ul style={{ listStyleType: "none" }}>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/aboutus/history">
                    <LinkOutlined /> College at a Glance
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/administrations/principal">
                    <LinkOutlined /> From the Desk of Principal
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/notifications/principal_activity">
                    <LinkOutlined /> Principal's Monthly Report
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/administrations/gb">
                    <LinkOutlined /> Governing Body
                  </Link>
                </li>

                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/departments">
                    <LinkOutlined /> All Departments
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/students/notification">
                    <LinkOutlined /> Student Notifications
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/anti-ragging">
                    <LinkOutlined /> Anti-Ragging
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/facility/library">
                    <LinkOutlined /> Library
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/facility/auditorium">
                    <LinkOutlined /> Auditorium
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/facility/conferencehall">
                    <LinkOutlined /> Conference Hall
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/newsletter">
                    <LinkOutlined /> Newsletter
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/gallery/photo">
                    <LinkOutlined /> Photo Gallery
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/gallery/document">
                    <LinkOutlined /> Document Gallery
                  </Link>
                </li>

                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <a href="https://complaints.dispurcollege.ac.in">
                    <LinkOutlined /> Complaints Management System
                  </a>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/iqac/teams">
                    <LinkOutlined /> IQAC Committee
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/iqac/ssr">
                    <LinkOutlined /> IQAC SSR
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/iqac/aqar">
                    <LinkOutlined /> IQAC AQAR
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/iqac/feedbacks">
                    <LinkOutlined /> Feedbacks
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/nirf">
                    <LinkOutlined /> NIRF
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/notifications/tender">
                    <LinkOutlined /> Tenders
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: "-20px",
                    fontWeight: 700,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 18,
                  }}
                >
                  <Link to="/contactus">
                    <LinkOutlined /> Contact Us
                  </Link>
                </li>
              </ul>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alginItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <img
                  src={
                    "https://img.freepik.com/premium-vector/plastic-free-icon-label-vector-set-no-plastic-product-sign-illustration_692379-630.jpg"
                  }
                  style={{ width: "70%" }}
                />
                <img
                  src={
                    "https://www.venkathomoeo.org/wp-content/uploads/2020/04/anti-ragging-2.png"
                  }
                  style={{ width: "80%" }}
                />

                <iframe
                  width="100%"
                  height="200px"
                  src="https://www.youtube.com/embed/umA0uZeT2Fk?si=6s0t74JcECN6XDOZ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullscreen
                ></iframe>
              </div>

              {/* <div className="row">
                <div className="col-md-12">
                  <a href="/aboutus/history">
                    <div className="">
                      <h4>History</h4>
                    </div>
                  </a>
                </div>
                <div className="col-md-12">
                  <a href="/facility/library">
                    <div className="">
                      <h4>Facilities</h4>
                    </div>
                  </a>
                </div>

                <div className="col-md-12">
                  <a href="/departments">
                    <div className="">
                      <h4>Departments</h4>
                    </div>
                  </a>
                </div>
              </div> */}
            </Grid>
          </Grid>
        </MDBContainer>

        <br />

        <div className="news py-4">
          <MDBContainer>
            <p
              style={{
                color: "white",
                fontSize: "2.7em",
                fontWeight: "600",
                textAlign: "center",
                margin: "2em 0 1em 0",
              }}
            >
              Notifications
            </p>

            <div className="row news-container">
              <div className="col-md-4 p-4">
                <Link to="/notifications/academic">
                  <h2 className="subhead">Student Notification</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_ACADEMIC_NOTICE" />
                  </div>
                </Link>
              </div>

              <div className="col-md-4 p-4">
                <Link to="/notifications/tender">
                  <h2 className="subhead">Tenders</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_TENDER" />
                  </div>
                </Link>
              </div>

              <div className="col-md-4 p-4">
                <Link to="/notifications/administrative">
                  <h2 className="subhead">Announcements</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_ADMIN_NOTICE" />
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="row news-container">
              <div className="col-md-4 p-4">
                <Link to="/notifications/academic">
                  <h2 className="subhead">Downloads</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_DOWNLOADS" />
                  </div>
                </Link>
              </div>

              <div className="col-md-4 p-4">
                <Link to="/notifications/tender">
                  <h2 className="subhead">Upcoming Events</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_TENDER" />
                  </div>
                </Link>
              </div>

              <div className="col-md-4 p-4">
                <Link to="/notifications/administrative">
                  <h2 className="subhead">Departmental News & Events</h2>
                  <div className="noticecard">
                    <HomePageNews type="COLLEGE_ADMIN_NOTICE" />
                  </div>
                </Link>
              </div>
            </div> */}
          </MDBContainer>
        </div>

        <div>
          <GraphContainer />
        </div>

        <MDBContainer>
          <div className="row my-4">
            <div className="col-md-4 col-sm-12 my-2">
              <a>
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/5.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>CCPC</h4>
                  <p>Career Counselling & Placement Cell (CCPC)</p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="./iqac/streategies">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/2.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>IQAC</h4>
                  <p>The Internal Quality Assurance Cell (IQAC) </p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="./committees">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/3.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Committees & Associations</h4>
                  <p>Different Committees and Associations of the College</p>
                </div>
              </a>
            </div>
          </div>
        </MDBContainer>
        <div style={{ backgroundColor: "#f9f9f9" }}>
          {/* Event Slider */}
          <MDBContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Hightlights />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <HomePageEvents />
              </Grid>
            </Grid>
          </MDBContainer>
        </div>
        

        <div style={{ backgroundColor: "white" }}>
          <MDBContainer>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Placements />
            </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Testimonials />
            </Grid>
            </Grid>
          </MDBContainer>
        </div>

        <HomePageFeatured />
       <VideoComponent />
        <TakeATour />

        <CollegeAnthem />
      </div>
    );
  }
}
