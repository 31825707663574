import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid
} from "@material-ui/core";
import { Tabs } from "antd";

class AboutDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "about" ? (
          <About title="About Dispur College" />
        ) : null}
        {this.props.type == "vision" ? <Vision title="Vision of Dispur College" /> : null}
        {this.props.type == "mission" ? <Mission title="Mission of Dispur College" /> : null}
        {this.props.type == "corevalues" ? <CoreValues title="Core Values of Dispur College" /> : null}
        {this.props.type == "history" ? <History title="College At a Glance" /> : null}
        {this.props.type == "past" ? <Vission title="Glorious Past" /> : null}
        {this.props.type == "icv" ? <ICV title="Recognitions" /> : null}
        {this.props.type == "certifications" ? <Certifications title="Certifications" /> : null}

        {/* {this.props.type == 'mission' ? (<Seat title="Mission" />) : null}} */}
      </div>
    );
  }
}

export default AboutDescription;

export const About = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>
      <hr />


      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
        Dispur College was established in the periphery of beautiful Narakasur Hills way back in 1978 by some visionaries of the locality primarily to cater the needs of children of the working parents in the Capital secretariat of Assam. The College has already completed 44th years of its glorious existence having 12 academic departments both in Arts and Commerce Stream along with highly efficient vocational departments in BBA & BCA. The College has always been looking forward in creating a strong bond between students and teachers for better performance in academic and other related activities. The institution has a primary objective of facilitation of higher education to the socio economic backward section of the society. With this motto, the College has been able to become a destination for large number of students across various communities and places of entire Northeast India. Among all social, cultural and other activities the students execute work unitedly for realization of basic objectives of education. In spite of some physical limitations, presently the college has an enrollment of 2000 students in different faculties pursuing their goals of life. A continuous effort is being made to transform the college into an ideal model institution not only for the state of Assam but also for the whole of North East India.
      </Typography>
      <br />

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
        Apart from academic activities, the College performs various socio-cultural & economic activities for the all-round development of the people residing around the adjacent localities of the college. The college has undertaken various schemes for community development as well. One such being the scheme of "Employment on Wheels" via which carts or similar models are provided to people to open stalls and find ways of being employed and earn livelihood. The college campus has already been made plastic free and it has also initiated several dialogues with stock holders of its adjacent areas to not to use single use plastic bags for delivery or carrying out of good or other items. For all round development of its students and other staffs and faculty members it has signed MOU's with various universities to facilitate better modes of higher education and employment generation. MOU has also been signed with NGO's to provide health care facilities to its students and staffs at a very nominal investment. The college has also been actively associated in organizing many national and international seminars of repute to enhance the rigorous process of research with social commitments.
      </Typography>
      <br />

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
        An ISO certified college, Dispur College has also been able to occupy Second Rank in that state level and Sixth (6) Position in the entire Eastern India in Arts Stream on a Survey conducted by a Magazine of National Repute “The Open”. The college also has taken numerous step to ensure gender equality and recently had become one of the first colleges in Assam to open a transgender toilet exclusively meant for that community. With a strong urge for enhancing and maintaining qualitative and quantitative progress, the college is in its journey to embrace development, newness, strong academic environment as well to insert its name amongst the top notch model colleges of the nation.
      </Typography>
    </div>
  );
};

export const Vision = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>
      <hr />


      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      Dispur College aspires to be a nationally acclaimed Higher Educational Institution. It is our persistent endeavour to remain resolute and unwavering in the pursuit of knowledge aligned with the motto of the institution “Tamso Ma Jyotirgamaya(तमसोमाज्योतिर्गमय)” and positively contribute towards holistic education which focus on nurturing student’s talent and personal development; promoting intellectual growth; developing professional skills; fostering gender equity and stimulating employability and to prepare them to work for holistic development of the society and the nation.
      </Typography>
      

      
    </div>
  );
};


export const Mission = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>
      <hr />


      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      Pledged to achieve our Vision through our Core Values and to educate the students to the highest levels of academic accomplishment, to empower them to reach and expand their prospective and to prepare them to become ethical, accountable, compassionate, productive, inventive members of the society as well as worthy citizens of the state.
      </Typography>
      

      
    </div>
  );
};

export const CoreValues = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>
      <hr />


      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>1. Integrity:</strong> Acting in accordance with the highest academic, professional and ethical standard​s is a priority for one and all representing the educational institution.
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>2. Responsibility:</strong> We are aware that our moves influence the world around us and based on that apprehension, we make the conscious choice to take actions to improve our community and the lives of each and everyone around us.
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>3. Diversity and Inclusion:</strong> Institution respects and appreciates differences in socio-economic background, education, ethnicity, colour, gender, age, caste, race, religion, physical abilities etc. among individuals. We share environment where everyone has equal opportunity to contribute ideas.
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>4. Excellence:</strong> We strive for excellence in all our endeavors as individuals, an institution, and a leader in higher education.
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>5. Teamwork:</strong> We understand that team values allows us to perform our role effectively and contribute to Institution's success. 
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>6. Transparency:</strong> We believe in accessibility, compliance, communication and accountability.
      </Typography>

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
      <strong>7. Environmental Sustainability:</strong> We value adopting practices for minimising our impact on the environment to the benefit of a more sustainable future for posterity.
      </Typography>
      

      
    </div>
  );
};

export const History = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
        {props.title}
      </Typography>
      <hr />

      {/* <Typography
                variant="h6"
                gutterBottom
            >
                History
            </Typography> */}

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>
        <div>
          <ol>
            <li><strong>1978</strong>-	 Dispur College was established on 28 August 1978 by opening the night shift in 			Gopal Boro Govt. H.S. School.</li>
            <li><strong>1983-84</strong>-	 The College Arts Stream was affiliated to Gauhati University and Assam Higher 			Secondary Education Council.</li>
            <li><strong>1987-88</strong>-	 The College shifted to its present location by opening the day shift.</li>
            <li><strong>1988-89</strong>- 	The College introduced Semi-Commerce in H.S level.</li>
            <li><strong>1991-92</strong>- 	The College started full-fledged Commerce Stream at the H.S level.</li>
            <li><strong>1992-93</strong>- 	The Arts Stream was brought under Deficit System of grant-in-aid.</li>
            <li><strong>1992</strong>-	The publication of the College Magazine “ARUN” was started.</li>
            <li><strong>1994-95</strong>-	The College started Commerce Stream at Degree level and received permanent 			affiliation of TDC Arts Stream from G.U.</li>
            <li><strong>1995-96</strong>-	The College was recognized by the UGC u/s 2F and 12B and received regular plan 		Grant accordingly.</li>
            <li><strong>2000-01</strong>-	The Commerce Stream obtained Govt. concurrence for General Courses.</li>
            <li><strong>2002-03</strong>-	The Internal Quality Assurance Cell  (IQAC) and Computer Centre were established. </li>
            <li><strong>2003-04</strong>-	The College was Accredited by NAAC (Cycle I) Graded B</li>
            <li><strong>2008-09</strong>-	Professional Courses like BBA/BCA were affiliated and recognized by the Gauhati 		University.</li>
            <li><strong>2009-10</strong>-	The Commerce Stream got Permanent Affiliation from the Gauhati University (EC R. 		No./EC-8/2009/222(ii) dated 27-11-2009)</li>
            <li><strong>2011-12</strong>-	The College registered under All India Survey of Higher Education (AISHE), MHRD, 		GOI and also introduction of Wi-Fi system in the College Campus.</li>
            <li><strong>2012-13</strong>-	Introduction of NLIST -INFLIBNET Centre at College Library.</li>
            <li><strong>2013-14</strong>-	Commerce Stream of the College is being covered under the Provincialization 			Scheme w.e.f.01-01-2013. </li>
            <li><strong>2014-15</strong>-	Full-fledged Commerce Block opened in the College Campus.</li>
            <li><strong>2015-16</strong>-	The College was Re-accredited by NAAC (Cycle II) Graded B; G.U. recognized the 			College as an Examination Zone for TDC (BA/B.COM).</li>
            <li><strong>2016-17</strong>-	Introduction of Wall Magazine in each and every department 					(Arts/Commerce/BBA/BCA)</li>
            <li><strong>In 2017</strong>-	College Magazine “Arun” was renamed as “Dispurian”.</li>
            <li><strong>2017-18</strong>-	Cashless transaction and e-tendering started.</li>
            <li><strong>2018-19</strong>-	Construction of Three Storied Roof-Top Auditorium, Canteen, Gymnasium, Student 		Union Room, Boy’s Common Room, NCC Room, Staff Quarters with State Govt. 			infrastructure funding included in The National Institutional Ranking Framework 		(NIRF) under MHRD Ranking Band (150-200)</li>
            <li><strong>2019-20</strong>-	Construction of Library & Toilet Block were initiated and subsequently completed 		under RUSA 1.0 and The National Institutional Ranking Framework (NIRF) under 			MHRD, Rank Band (150-200).</li>
            <li><strong>2020-21</strong>-	Introduction of Online Classes as per guidelines of State Govt. and G.U. The College 		also participated under NIRF 2020. </li>
            <li><strong>2021-22</strong>-	Construction of Entrance Path (Brick Block) and Cycle Shed under RUSA 1.0 fund. The College also participated under NIRF 2020.     </li>
            <li><strong>2022-23</strong>- 
            <ul>
              <li>Introduction of Certificate Courses by each department.</li>
<li>Attainment of ISO Certificate ISO 9001:2015 Quality Management System.</li>
<li>Attainment of ISO Certificate 21001:2018 Educational Organization Management System.</li>
<li>Provision of Baby Day Care Room.</li>
<li>Conduct of Green Audit and Energy Audit in the year 2023.</li>
<li>Provision of facilities for divyangjan.</li>
<li>Pre-Examination Coaching for SSC, Civil Services, Railways Recruitment Board, Banking
Services and other such competitive exams.</li>
            </ul></li>

            <li><strong>2023-24</strong>
            <ul>
              <li>Construction of new Administrative Block Building</li>
              <li>Installation of Climate Clock by the 48 ANU NCC, Dispur College</li>
              <li>Universal access to Wi-Fi Facility within College Premises</li>
              <li>Construction of Cafeteria</li>
              <li> Creation of new Computer Laboratory</li>
              <li>New Conference Hall-cum-Digital Classroom</li>
              <li>Provision of Elevator and Ramp Facilities for Ease-of-Access</li>
              <li>Inclusive Learning Facility (e.g.: Braille-aided learning tools for Specially-Abled Children)</li>
              <li>Construction of Infrastructure including Revamped College Gate and Girls’ Common Room</li>
            </ul></li>


            <li><strong>2024-25 (Upcoming)</strong>
            <ul>
              <li>Recreation Zone catering to Fun-and-Learn Pedagogy</li>
              <li>Sitting Gallery</li>
              <li>Digital Bulletin Board</li>
              <li>Reaccreditation of the College by the National Accreditation and Assessment Council</li>
              <li>Community Radio Station</li>
              <li>Media Studio</li>
            </ul></li>

          </ol>
          {/* <p className="text-justify">NAAC Visit NAAC Peer Team visit to Dispur College, Ganeshguri, Dispur, Guwahati-6, Assam during 31st August 2015 to 2nd September 2015 for accreditation (Cycle-II). VERIFICATION OF STUDENTS RECORDS The College Authority will verify only the certificate issued by the College Authority on submission of hard copy of the document together with bank draft of Rs 100/- in favour of "Principal, Dispur College" payable at BOB, Dispur Branch, Guwahati and send to the college postal address. There should not be any communication through email or telephone. College Address: Office of the Principal Dispur College Ganesguri, Dispur Guwahati 781006 Kamrip (M), Assam Achievement All Guwahati Ridip Dutta Memorial Chords Students from the College participated in All Guwahati Ridip Dutta Memorial Chords competition and won the 2nd prize on 26.10.2014.</p> */}
        </div>
      </Typography>
    </div>
  );
};

export const Vission = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }}>
        {props.title}
      </Typography>
      <hr />


      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>

        Dispur College was established in 1978, with classes being held at Gopal Boro Govt.
        Higher Secondary School in the evening shift with arts faculty only. The founder principal
        was Sri Ghana Kanta Chetia Phukan. The college came into existence to meet the growing
        demand for higher education due to the shifting of the capital of Assam from Shillong to Dispur.
        During the tenure of Principal Reba Kanta Hazarika the college shifted to its present premises
        in 1987, at the foothill of Narakasur Hill near Ganeshguri Chariali, Dispur, in the heart of
        Guwahati City. The night shift was discontinued and classes were converted to day shift.
        A morning shift was introduced to meet the demands of the students of the locality.
        From the academic session 1991-92 the commerce stream was introduced. Due to changing
        needs and demand for professional education, the college introduced BBA and BCA from the session 2007-08.
        The College also provides a platform for open learning via the K.K. Handique State open university.
      </Typography>
    </div>
  );
};

const { TabPane } = Tabs;



export const ICV = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }}>
        {props.title}
      </Typography>
      {/* <hr /> */}

      {/* <div style={{textAlign: 'center'}}>

      {/* <img
        src={`/images/THEME_SONG.jpg`}
        style={{width:'600px'}}
      />


      <br />
      <br />

      <img
        src={`/images/CORE_VALUES.jpg`}
        style={{width:'500px'}}
      /> */}

      {/* </div> */}
      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>

        The College (Arts and Commerce Stream) is affiliated to Gauhati University and Assam Higher Secondary Education Council.
        The Arts Stream was Provincialised by the Govt. of Assam in 2006. The College was recognized by the UGC U/S 2F and
        12B and received regular Plan Grant from 8th Plan onwards. National Assessment and Accreditation Council (NAAC),
        Bangalore recognized the college at the National level in 2004 and was Graded "B" The Commerce Stream obtained Govt.
        Concurrence for General Courses in April 2001. The Commerce Stream has been getting Financial Assistance as Non-Govt.
        Degree Colleges under State Priority Scheme from Govt. of Assam from the financial year 2006-2007.
        The Commerce Stream got Permanent Affiliation from Gauhati University (EC R. No./EC-8/2009/222(ii)
        dated 27-11-2009) IGNOU recognized the College as a Partner Institute for Convergence Scheme and offers
        various courses of Masters Degree, Bachelors Degree, PG Diploma, Diploma and Certificate. KKHSOU recognized
        the College as a Study Centre / Examination Centre for various courses of Bachelors Degree, Diploma and Certificate.
        ICWAI Eastern Regional Centre recognised the college as an Oral Coaching Centre for ICWAI. UGC also recognized the College for
        Add On Courses at the Undergraduate Level and provided seed money. Accordingly, the college offered two courses viz.
        Diploma in Child Education and Certificate in Entrepreneurship Development which are also affiliated to the Gauhati University.
        Professional Courses like BBA / BCA affiliated and recongnised by the Gauhati University from the Session 2008-09
        The AHSEC (Assam Higher Secondary Education Council) recognize the college as ASOS Centre. Institute of Distance and
        Open Learning (IDOL) of Gauhati University recognize the college as its Study Centre for IT Courses.
      </Typography>

      <br />
      

      <Typography variant="body1" gutterBottom style={{textAlign: "justify"}}>

        NAAC Peer Team visit to Dispur College, Ganeshguri, Dispur, Guwahati-6, Assam during 31st August 2015 to 2nd September 2015 for accreditation (Cycle-II).
      </Typography>
    </div>
  )
}


export const Certifications = (props) => {
  return (
    <div>
      <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }}>
        {props.title}
      </Typography>
      {/* <hr /> */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <img
          src={`/images/dispur-iso.jpeg`}
          style={{ width: '100%', height: '500px', objectFit: "contain" }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <img
          src={`/images/dc-iso-1.png`}
          style={{ width: '100%', height: '500px', objectFit: "contain" }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <img
          src={`/images/dispur-nipam.png`}
          style={{ width: '100%', height: '500px', objectFit: "contain" }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <img
          src={`/images/dc-sdg-certificate.jpeg`}
          style={{ width: '100%', height: '500px', objectFit: "contain" }}
        />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <img
          src={`/newimages/dc-ac.jpeg`}
          style={{ width: '100%', height: '500px', objectFit: "contain" }}
        />
        </Grid>
      </Grid>

      

      

    </div>
  )
}